import React, { useState } from 'react';
import images from '../../../assets';
import { FiHeart } from 'react-icons/fi';
import { BiMap, BiShareAlt, BiUser } from 'react-icons/bi';
import Button from '../../../UI_Elements/Button/Button';
import { AiOutlineCalendar } from 'react-icons/ai';
import { FaMapMarkerAlt } from 'react-icons/fa';
import EventCardToast from '../../Reusables/Toast/EventCardToast';
import SocialShareModal from '../../Reusables/Modals/SocialShareModal';
import TicketCheckoutModal from '../../Reusables/Modals/TicketCheckOutModal';
import moment from 'moment'
import { getHtmlText } from '../../../util/functions';
import QRCode from 'react-qr-code';

const MultipleCardSection = ({name, start_time, description, address, state, image:pictureImage, availability, organiser}) => {
    
    const [favourite, setFavourite] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);

    const handleFavouriteClick = () => {
        const updatedFavourite = !favourite;

        if (updatedFavourite) {
            EventCardToast({
                message: "Successfully added to favorites",
                success: true,
            });
        } else {
            EventCardToast({
                message: "Removed from favorites",
                success: false,
            });
        }

        setFavourite(updatedFavourite);
    };

    const openShareModal = () => {
        setIsShareModalOpen(true);
    };

    const closeShareModal = () => {
        setIsShareModalOpen(false);
    };

    const openCheckoutModal = () => {
        setIsCheckoutModalOpen(true);
    };

    const closeCheckoutModal = () => {
        setIsCheckoutModalOpen(false);
    };

    return (
        <>
            <div className="flex flex-col lg:flex-row justify-center gap-8 mt-5 md:mt-10">
                <div className="flex-[.6] p-2">
                    <div className="bg-white rounded-lg drop-shadow-lg">
                        <div className="p-4">
                            <img src={pictureImage || "https://tickcit-space.fra1.digitaloceanspaces.com/events/default.jpg"} 
                                alt={images.eventImg.alt} className=' h-96 mx-auto w-auto' 
                                onError={(ev) => {
                                    ev.target.src = "https://tickcit-space.fra1.digitaloceanspaces.com/events/default.jpg";
                                }}
                            />
                        </div>

                        <div className="flex py-4 px-6">
                            <h3 className='text-xs xs:text-xl md:text-3xl max-w-lg text-text-color-400'>{name}</h3>
                            {/* <div className="flex gap-4 items-end md:items-center py-1 justify-end flex-1">
                                <FiHeart
                                    fill={favourite ? '#FF4E4E' : 'transparent'}
                                    className={`text-xl sm:text-2xl transition ${favourite ? 'text-danger-400' : '!text-text-color-400'} cursor-pointer`}
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevent event propagation
                                        handleFavouriteClick();
                                    }}
                                />
                                <BiShareAlt
                                    className="text-xl sm:text-2xl transition !text-text-color-400 cursor-pointer"
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevent event propagation
                                        openShareModal();
                                    }}
                                />
                            </div> */}
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 p-2 mt-5 bg-white px-4 py-6 rounded-lg drop-shadow-lg">
                        <span className='text-base md:text-xl text-text-color-400 font-semibold max-w-lg'>Description</span>
                        <p className='text-xs xs:text-sm md:text-base text-text-color-300 max-w-lg text-wrap font-semibold'>{getHtmlText(description) || ""}</p>
                    </div>
                </div>

                <div className="lg:flex-[.4]">
                    <div className="flex flex-col gap-3 p-2 mt-2 bg-white px-4 py-6 rounded-lg drop-shadow-md">
                        <span className='text-base md:text-xl text-text-color-400 font-semibold max-w-lg'>Organizer</span>
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-2">
                                <BiUser className='text-xl md:text-3xl text-text-color-400' />
                                <span className='text-text-color-400 text-xxs xs:text-xs md:text-sm xl:text-base'>{organiser ? `${organiser?.business_name || `${organiser?.first_name} ${organiser?.last_name || 'Not Set'}`}` : "Tickit Ticketing Company"}</span>
                            </div>
                            <Button className="invisible !px-4 !py-2 !rounded-[100px] font-semibold border border-primary !text-primary !text-xs sm:!text-sm hover:bg-primary hover:!text-white transition" textWhite shadow>Follow</Button>
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 p-2 mt-6 bg-white px-4 py-6 rounded-lg drop-shadow-md">
                        <span className='text-base md:text-xl text-text-color-400 font-semibold max-w-lg'>Date & Time</span>
                        <div className="flex items-center">
                            <div className="flex items-center gap-2">
                                <AiOutlineCalendar className='text-xl md:text-3xl text-text-color-400' />
                                <span className='text-text-color-400 text-xxs xs:text-xs md:text-sm xl:text-base'>{moment(start_time).format('ddd, MMM DD · HH:mm:ss A') || "Time not set"} </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 p-2 mt-6 bg-white px-4 py-6 rounded-lg drop-shadow-md">
                        <span className='text-base md:text-xl text-text-color-400 font-semibold max-w-lg'>Location</span>
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-2 flex-wrap">
                                <BiMap className='text-xl md:text-3xl text-text-color-400' />
                                <span className='text-text-color-400 text-xxs xs:text-xs md:text-sm xl:text-base'>{`${address} ${state}`}</span>
                            </div>
                            <a href={`http://maps.google.com/?q="${address}, ${state}"`} rel="noreferrer" target="_blank" className="flex items-center gap-1 md:gap-2">
                                <FaMapMarkerAlt className='text-sm xs:text-xl md:text-2xl text-primary cursor-pointer' />
                                <span className='text-primary text-xxs xs:text-sm xl:text-lg cursor-pointer hover:scale-90 transition'>View on Map</span>
                            </a>
                        </div>
                    </div>

                    <div style={{ height: "auto", margin: "20px auto",}} className='w-32 lg:w-40'>
                        <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={window.location.href}
                        viewBox={`0 0 256 256`}
                        />
                    </div>

                    <div className="flex flex-col gap-3 mt-6 bg-white px-8 sm:px-40 lg:px-16 py-2 md:py-4 rounded-lg drop-shadow-md mx-12 lg:mx-0">
                        <Button
                            className="!px-2 !py-3 !rounded-sm font-semibold bg-primary-dark hover:drop-shadow-xl hover:bg-primary !text-xs md:!text-base transition"
                            textWhite shadow
                            onClick={openCheckoutModal}
                        >Buy Ticket</Button>
                    </div>
                </div>
            </div>
            <SocialShareModal
                isOpen={isShareModalOpen}
                onClose={closeShareModal}
            />

            <TicketCheckoutModal
                address={address||""} state={state||""} name={name||""} time={moment(start_time).format('ddd, MMM DD · HH:mm:ss A') || ""}
                availability={availability|| {}} isOpen={isCheckoutModalOpen} onClose={closeCheckoutModal} date={0} event_type={null}
            />
        </>
    );
};

export default MultipleCardSection;