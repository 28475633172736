import React, { useState } from 'react';
import { motion } from "framer-motion";
// import * as Iconf from "react-icons/fi";
// import * as Iconb from "react-icons/bi";
import { HiMapPin } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import SocialShareModal from '../Modals/SocialShareModal';
// import EventCardToast from '../Toast/EventCardToast';

const FeaturedCard = ({ id, icon, iconAlt, title, location, time, x, image, event_type }) => {
    // const [favourite, setFavourite] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    
    
    // const navigate = (path) => {
    //     window.location.href = path;
    // };
    const navigate = useNavigate()

    // const handleFavouriteClick = () => {
    //     const updatedFavourite = !favourite;

    //     if (updatedFavourite) {
    //         EventCardToast({
    //             message: "Successfully added to favorites",
    //             success: true,
    //         });
    //     } else {
    //         EventCardToast({
    //             message: "Removed from favorites",
    //             success: false,
    //         });
    //     }

    //     setFavourite(updatedFavourite);
    // };

    // const openShareModal = () => {
    //     setIsShareModalOpen(true);
    // };

    const closeShareModal = () => {
        setIsShareModalOpen(false);
    };

    return (
        <>
            <motion.div
                initial={{ y: 50, opacity: 0.5 }}
                whileInView={{ y: 0, transition: { duration: 0.4, ease: 'easeOut', delay: 0.1 }, opacity: 1 }}
                whileHover={{ scale: 1.03 }}
                key={id}
                className="flex flex-col sm:flex-row pb-1 sm:p-3 bg-white gap-2 rounded-lg hover:drop-shadow-xl drop-shadow-md transition cursor-pointer flex-shrink"
                onClick={() => navigate(`/browse/${id}?event_type=${event_type}`)}
            >
                <img
                    src={image || "https://tickcit-space.fra1.digitaloceanspaces.com/events/default.jpg"}
                    alt={iconAlt}
                    className="object-cover w-60 sm:w-40 lg:w-32 xl:w-40 h-32    mx-auto" 
                    onError={(ev) => ev.target.src = "https://tickcit-space.fra1.digitaloceanspaces.com/events/default.jpg"}
                />

                <div className="flex flex-col items-center sm:items-start p-2 gap-1 sm:gap-2">
                    <h3 className="text-text-color-400 text-base sm:text-2xl font-[600] text-center sm:text-start truncate w-44">{title}</h3>
                    <span className="text-sm sm:text-base text-center sm:text-start truncate w-40">{time}</span>
                    <div className="flex justify-center items-center sm:items-start sm:justify-start gap-2">
                        <HiMapPin className="text-xl sm:text-2xl transition !text-text-color-400" />
                        <span className="text-xs sm:text-base truncate w-48 text-center sm:text-start">{location}</span>
                    </div>
                </div>

                {/* <div className="flex p-2 gap-6 sm:gap-4 items-end justify-center sm:justify-end flex-wrap invisible">
                    <Iconf.FiHeart
                        fill={favourite ? '#FF4E4E' : 'transparent'}
                        className={`text-xl sm:text-2xl transition ${favourite ? 'text-danger-400' : '!text-text-color-400'}`}
                        onClick={(event) => {
                            event.stopPropagation(); // Prevent event propagation
                            handleFavouriteClick();
                        }}
                    />
                    <Iconb.BiShareAlt
                        className="text-xl sm:text-2xl transition !text-text-color-400"
                        onClick={(event) => {
                            event.stopPropagation(); // Prevent event propagation
                            openShareModal();
                        }}
                    />
                </div> */}
            </motion.div>

            <SocialShareModal
                isOpen={isShareModalOpen}
                onClose={closeShareModal}
            />
        </>
    );
};

export default FeaturedCard;