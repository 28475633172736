import React, { useContext,useState } from 'react'
import { images } from '../../../assets'
import Input from '../../../UI_Elements/Input/Input'

// custom util function
import {VALIDATOR_EMAIL, VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE} from '../../../util/validators'

// custom hooks
import {useForm} from '../../../hooks/form-hook'
import { useHttpClient } from '../../../hooks/http-hook'
import { AuthContext } from '../../../context/auth-context'

//custom components
import PhoneInput from 'react-phone-input-2'
import ReactSelect from '../../Reusables/select/ReactSelect'
import Button from '../../../UI_Elements/Button/Button';

import { DataContext } from '../../../context/DataContext'
import NaijaStates from 'naija-state-local-government';
import Loader from '../../../UI_Elements/loader/Loader'
import PhotoUpload from './PhotoUpload'
import { useNavigate } from 'react-router-dom'


function Profile() {
    const {isLoading, sendRequest} = useHttpClient();
    const dataContext = useContext(DataContext).userData
    const [isPhotoUpload, setIsPhotoUpload] = useState(false)
    const navigate = useNavigate()
    
    const auth = useContext(AuthContext)
    const data  = JSON.parse(localStorage.getItem("profileData"))?.user || null
    
    const [gender, setGender] = useState((prevdata) =>{
        return {
            ...prevdata,
            label:data?.gender || "", 
            value:data?.gender || "",
        }
    })
    const naijaStates = NaijaStates.states()

    const naijaStatesObject = naijaStates.map(item => {
        return {
          label: item,
          value: item.toLowerCase()
        };
      })

    const statusOptions = [
        {
          label:"Male", 
          value:"male"
        },
        {
          label:"Female", 
          value:"female"
        }
      ]

    const [states, setStates] = useState((prevData) =>{
        return{
            ...prevData,
            label:data?.city || "", 
            value:data?.city || ""
        }
    })

    const [phone,
        setPhone] = useState(data?.phone_number || dataContext?.phone_number || "")

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        },
        firstname: {
            value: '',
            isValid: false
        },
        lastname: {
            value: '',
            isValid: false
        },
        address: {
            value: '',
            isValid: false
        },
    }, false);


    const submitHandler = async event => {
        event.preventDefault();

        try {

            const formData = new FormData()

            formData.append('email_address', formState.inputs.email.value)
            formData.append('first_name', formState.inputs.firstname.value)
            formData.append('last_name', formState.inputs.lastname.value)
            formData.append('phone_number', phone)
            formData.append('city', states?.value || "")
            formData.append('gender', gender?.value || "")
            formData.append('address', formState.inputs.address.value || "")
            
            const response = await sendRequest(`${process.env.REACT_APP_API_CALL}/user/update-details`, 'PATCH', formData, {Authorization: 'Bearer '+auth.token});

            localStorage.setItem('profileData', JSON.stringify(response.data));

        } catch (error) {
            console.log(error)
        }
        
    };      

    const handlePhotoUpload = () => {
        setIsPhotoUpload(!isPhotoUpload)
    }   

    const phoneVerificationHandler = async () => {

        const formData = new FormData()

        formData.append('user_id', dataContext.id || "" )
        formData.append('phone_number', phone || "" )

        try{
            
            const response = await sendRequest(`${process.env.REACT_APP_API_CALL}/user/add-phone`, 'POST', formData, {Authorization: 'Bearer '+auth.token});

            if(response){
                navigate('/phone-verification')
            }

        } catch (error) {
            console.log(error)
        }
        
    }

  return (
    <>
    <PhotoUpload show={isPhotoUpload} onCancel={handlePhotoUpload} 
    onUpload={(images) => {
        console.log(images)
    }}/>
    {isLoading && <Loader/>}
    <div className='px-2 md:px-4 xl:px-8 py-4 flex flex-col md:flex-row items-center md:items-start gap-6 md:gap-x-8 lg:gap-x-16 w-full'>
        <div className='flex flex-col items-center justify-center'>
            {/* <h3>Profile</h3> */}
            
            <img 
            src={data?.profile_picture || images.logo["src"]} 
            alt={images.logo["alt"]} 
            className="w-24 h-24 rounded-full border border-primary-green-dark my-2" 
            onError={(ev) => {
                ev.target.src = images.logo["src"]
              }}/>
            <button onClick={() => handlePhotoUpload()} className={`px-6 py-2 rounded text-white text-sm bg-primary`}>
                Update
            </button>
        </div>

        <form 
        
        className={`mt-4 grid grid-cols-1 md:grid-cols-2  
        gap-4 lg:gap-x-8 items-center place-items-start w-full`} onSubmit={e => {
            e.preventDefault();
            submitHandler(e)}}>
        <Input
            type="text"
            placeholder='Enter your first name'
            label="First Name"
            labelClassName={`text-sm mb-2 font-semibold form_login_label`}
            id="firstname"
            initialValue={data?.first_name || ""}
            initialValid={data ? true : false}
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a valid first name"
            onInput={inputHandler}
            divClassName={`mb-3 `}
            containerClassName={`relative  `}
            className={`appearance-none ${formState.inputs.firstname.value && "bg-light-purple"}  
            input-form p-3 text-primary-black-70 focus:outline-none 
            focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>

        <Input
            type="text"
            placeholder='Enter your last name'
            label="Last Name"
            labelClassName={`text-sm mb-2 font-semibold form_login_label`}
            id="lastname"
            initialValue={data?.last_name || ""}
            initialValid={data ? true : false}
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a valid last name"
            onInput={inputHandler}
            divClassName={`mb-3 `}
            containerClassName={`relative  `}
            className={`appearance-none ${formState.inputs.lastname.value && "bg-light-purple"}  
            input-form p-3 text-primary-black-70 focus:outline-none 
            focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>

        <Input
            type="email"
            placeholder='Enter your email address'
            label="Email Address"
            labelClassName={`text-sm mb-2 font-semibold form_login_label`}
            id="email"
            initialValue={data?.email_address || ""}
            initialValid={data ? true : false}
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email"
            onInput={inputHandler}
            divClassName={`mb-3 `}
            readOnly
            containerClassName={`relative`}
            className={`appearance-none 
            input-form input-fade p-3 text-primary-black-70 focus:outline-none 
            focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>
        
        <div className='w-full mb-6 md:mb-3'>
            <label className='text-sm mb-2 -mt-1 font-semibold'>Phone Number</label>
            
            <PhoneInput 
                country={'ng'}
                value={phone}
                onChange={phone => setPhone(phone)}
                placeholder="900 009 211"
                inputProps={{
                name: 'phone',
                required: true
                }}
                containerClass={`w-full mt-1`}
                inputClass={`phone_input w-full`}/>

                <div className={`flex items-center mt-1 text-xxs`}>
                    <div className='w-3 h-3 p-2 bg-black flex__center rounded-full text-white'>
                        !   
                    </div>
                    <p className='text-primary-gray ml-1'>Not verified</p>
                </div>
            
            
        </div>

        

        <div className='w-full mb-6' >
            <p className='text-sm 6b-2 font-semibold form_login_label'>Gender</p>
            <ReactSelect 
            options={statusOptions} 
            className="appearance-none padding-3 input-form sm:text-sm focus:z-10"
            input={(data) => {
                setGender(prevData => {
                    return{
                        ...prevData,
                        ...data
                    }
                })
            }} 
            value={gender}
            placeholder="Gender"/>
        </div>

        <div className='w-full mb-6' >
            <p className='text-sm mb-2 font-semibold form_login_label'>City</p>
            <ReactSelect 
            options={naijaStatesObject} 
            className="appearance-none padding-3 input-form sm:text-sm focus:z-10 "
            input={(data) => {
                setStates(prevData => {
                    return{
                        ...prevData,
                        ...data
                    }
                })
            }} 
            value={states}
            placeholder="State of residence"/>
        </div>

        
        <div className='md:col-span-2 w-full'>
            <Input
                type="text"
                placeholder='Enter a valid home address'
                label="Address"
                labelClassName={`text-sm mb-2 font-semibold form_login_label`}
                id="address"
                initialValue={data?.address || ""}
                initialValid={data?.address ? true : false}
                validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(5)]}
                errorText="Please enter a valid address"
                onInput={inputHandler}
                divClassName={`mb-3 `}
                containerClassName={`relative  `}
                className={`appearance-none ${formState.inputs.email.value && "bg-light-purple"}  
                input-form p-3 text-primary-black focus:outline-none 
                focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>
        </div>
        
            <div className='w-full md:col-span-2 mt-6 flex justify-end'>
                <div className="flex justify-end items-center gap-3">
                    <Button type="button" onClick={phoneVerificationHandler} bgPrimary textWhite shadow  className={`ml-auto !px-12 !bg-primary-dark hover:!bg-primary `} 
                    >
                        verify phone number
                    </Button>
                    <Button type="submit" bgPrimary textWhite shadow  className={`ml-auto !px-12 !bg-primary-dark hover:!bg-primary `} 
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
        </form>

        
    </div>
    </>
  )
}

export default Profile