import React, {useMemo, useEffect, useState, useContext} from 'react'

import {useNavigate} from 'react-router-dom'

// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import {AuthContext} from '../../../../context/auth-context';
import { useHttpClient} from '../../../../hooks/less-http-hook'

// DATA
import {TRANSACTION_COLUMNS} from '../../../../util/columns/columns';


//custom components
import Loader from '../../../../UI_Elements/loader/Loader';
// import TablePagination from '../../../Reusables/TablePagination/TablePagination';

// custom css
import './tables.css'
import { DataContext } from '../../../../context/DataContext';




function HistoryTable(props) {    

    const auth = useContext(AuthContext);

    const userData  = useContext(DataContext)?.userData;

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"15",
        "status":"0",
        "search_text":""
    });

    // const [pages, setPages] = useState([]);

    // const [refresh, setRefresh] = useState(false);

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        setTableData(null);

        const formData = new FormData();
        formData.append('page_number',detailsData.page_number);
        formData.append('status',detailsData.status);

        const fetchData = async() => {
            try {
                const responseData = await 
                sendRequest(`${process.env.REACT_APP_API_CALL}/transaction/list-all?user_id=${userData.id}&page_num=${detailsData.current_page}&page_size=${detailsData.page_number}`, 
                "GET", 
                null , 
                {Authorization: 'Bearer '+auth.token});
                setTableData(responseData.data);
                

                if(responseData){
                    setCellData([])
                    for(let file of responseData.data.transactions){

                        setCellData((cell)=>[...cell, {
                            "id":file.id,
                            "amount": file.amount,
                            "reference": file.reference,
                            "status": file.status,
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchData();

    }, [sendRequest, error, auth, detailsData]);





    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }



    const columns = useMemo(() => TRANSACTION_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    return (
        <>


        {isLoading ? <Loader/> :
        
        <div className="bg-white pb-8 table__border mt-6 overflow-x-auto w-full">
             
            <table {...getTableProps()} className="w-full text-left">
                <thead className='border-b border-border-color w-full'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="">
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs md:text-sm text-primary-black-70 font-medium whitespace-nowrap p-4 px-6">{column.render('Header')}</th>
                                ))}
                            
                            <th className="text-xs md:text-sm text-primary-black-70 font-medium whitespace-nowrap p-4"></th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()} className='bg-odd-row font-sora'>
                                {/* <td className="text-sm py-4 whitespace-nowrap font-gilroy-medium">{index+1}</td> */}
                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        if(row.original.status === "finalised"){
                                            return <td {...cell.getCellProps()} 
                                            className="border-t-0 px-6  align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left">
                                                <p className='text-primary-grey'>{cell.render('Cell')}</p>
                                            </td>
                                        }
                                    })}
                                
                            
                            </tr>
                        )
                    })}
                    
                </tbody>

            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            {/* {tableData && (
                <TablePagination 
                current_page = {tableData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                pages = {pages}
                per_page = {tableData.per_page}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )} */}

            
            
        </div>
        }
        </>              
    )
}

export default HistoryTable